<template>
  <div class="navigation">
    <div class="container">
      <nav class="navbar">
        <div class="navbar-brand">
          <RouterLink :to="{ name: 'home' }" class="navbar-item">
            <span>{{ $t("header.title") }}</span>
            <span class="light is-hidden-desktop-only">
              {{ $t("header.switzerland") }}
            </span>
          </RouterLink>
          <div class="navbar-burger" @click="toggleNavigation">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div
          class="navbar-menu"
          :class="{ 'show-mobile-menu': showMobileMenu }"
        >
          <div class="navbar-start"></div>

          <div class="navbar-end">
            <RouterLink
              :to="{ name: 'planner' }"
              class="navbar-item active-border is-size-6"
              active-class="is-active"
            >
              {{ $t("header.links.courses") }}
            </RouterLink>
            <RouterLink
              :to="{ name: 'seminar-overview' }"
              class="navbar-item active-border is-size-6"
              active-class="is-active"
            >
              {{ $t("header.links.seminars") }}
            </RouterLink>
            <RouterLink
              :to="{ name: 'specializations' }"
              class="navbar-item active-border is-size-6"
              active-class="is-active"
            >
              {{ $t("header.links.specializations") }}
            </RouterLink>
            <RouterLink
              :to="{ name: 'coach-developer' }"
              class="navbar-item active-border is-size-6"
              active-class="is-active"
            >
              {{ $t("header.links.coachDeveloper") }}
            </RouterLink>
            <RouterLink
              :to="{ name: 'courselist' }"
              class="navbar-item active-border is-size-6"
              active-class="is-active"
            >
              {{ $t("header.links.schedule") }}
            </RouterLink>
            <div class="is-flex mobile-flex-container">
              <div class="navbar-item is-flex">
                <LocaleSwitcher />
              </div>

              <div class="wishlist">
                <RouterLink
                  :to="{ name: 'wishlist' }"
                  class="navbar-item is-flex-touch"
                >
                  <div class="wishlist-container">
                    <div class="wishlist-icon">
                      <SvgIcon name="wishlist" />
                      <span v-if="wishlistCount" class="wishlist-count">
                        {{ wishlistCount }}
                      </span>
                    </div>
                  </div>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import SvgIcon from "@/components/SvgIcon.vue";

import { useWishlistStore } from "@/store/Wishlist";

const route = useRoute();

const wishlistStore = useWishlistStore();

const overviewActive = ref(false);
const showMobileMenu = ref(false);

const { wishlistCount } = storeToRefs(wishlistStore);

const toggleNavigation = () => {
  showMobileMenu.value = !showMobileMenu.value;
};

watch(
  () => route.path,
  () => {
    // check if current page is the overview page while ignoring url query parameters
    // <router-link exact> also checks the query parameter, an we (ab)use the query parameter to switch languages,
    // so a language change on the overview page would not mark the link as active
    overviewActive.value = route.path === "/";

    // hide mobile menu after triggering a navigation
    showMobileMenu.value = false;
  },
);
</script>

<style lang="scss" scoped>
@import "@/assets/css/settings.scss";

.navbar {
  background: $red;
}

.navigation {
  position: fixed;
  position: sticky;
  z-index: 8888;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

@media screen and (min-width: $desktop) {
  .navbar {
    height: 5.625rem;
  }

  .navbar-brand {
    font-size: $size-4;
  }
}

.navbar .navbar-item {
  color: $white;
  font-weight: $fira-bold;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  border-bottom: solid 0.3rem transparent;
  background: none;

  @media only screen and (max-width: ($tablet - 1px)) {
    border-bottom: none;
  }

  .light {
    font-weight: $fira-regular;
    margin-left: 0.4rem;
  }

  @media screen and (min-width: $desktop) {
    &.active-border {
      &:hover,
      &.is-active {
        border-color: $white;
        color: $white;
      }
    }
  }

  &:last-of-type {
    margin-right: 3rem;
  }
}

@media screen and (max-width: ($desktop - 1px)) {
  .mobile-flex-container {
    flex-direction: column-reverse;
  }
}

.navbar-menu {
  background-color: $red;

  &.show-mobile-menu {
    display: block;
  }
}

.navbar-brand {
  .navbar-item:hover {
    border-color: transparent;
  }

  @media screen and (max-width: ($desktop - 1px)) {
    .navbar-item {
      max-width: 85%;
    }
  }
}

.navbar-burger {
  color: $white;
}

.wishlist {
  display: flex;
  align-items: center;

  .wishlist-container {
    display: inline-flex;
    align-items: center;
  }

  .navbar-item {
    font-weight: $fira-regular;
    font-size: 0.875rem;
    letter-spacing: 0.03125rem;

    &:hover {
      border-color: transparent;
    }

    svg {
      margin-right: 0.5rem;
      max-height: 100%;
      fill: $white;
    }
  }
}

@media screen and (max-width: ($desktop - 1px)) {
  .wishlist .caption {
    font-size: $size-5;
    font-weight: $fira-bold;
  }
}

.wishlist-icon {
  width: 32px;
  height: 32px;
  margin-right: 1rem;

  svg {
    width: 100%;
    height: 100%;
  }

  .wishlist-count {
    position: absolute;
    top: 20px;
    left: 35px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: $white;
    text-align: center;
    color: $black;
    font-size: 0.7rem;
    line-height: 17px;
    padding-left: 1px;
  }
}
</style>
<style lang="scss">
.navbar-burger span:nth-child(1){
  top: calc(50% - 6px)!important;
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px)!important;
}
</style>
